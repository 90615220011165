export default{
    home:{
        home:'首页',
        product:'产品方案',
        user:'关于我们',
        marketTitle:'溯源营销',
        existEvidenceTitle:'溯源存证',
        onlineRetailersTitle:'云美装商户平台',
        supplyChainTitle:'供应链平台',
        distributionTitle:'分销平台',
        paymentTitle:'支付服务平台',
        market:'溯源营销服务是利用相关技术将产品生产制造流通交易全环节的信息与流通主体信息进行上链存储。保证产品信息透明，全流程任何环节均可真实追溯，提升产品与品牌可信度。同时通过扫描溯源码嵌入的营销活动完成用户线上与线下的闭环。',
        existEvidence:'存证服务是针对已经有成熟业务流程在线上运营的企业，通过丰富的上链接口，智能合约，为全行业提供上链服务。使得存证无法篡改，便于被各方共享，并作为纠纷发生时的电子证据，实现全流程留痕，全链路可信，全节点见证，高效解决企业纠纷，降低企业风控成本，营造互信的商业环境。',
        onlineRetailers:'云美装商户平台是基于互联网思维，整合软装行业资源，运用区块链、大数据、云计算、人工智 能等技术，建立的软装产业数字化运营服务平台。向商户提供强大的商城系统和完整的移动电商解决方案。',
        supplyChain:'生产制造企业可一键分销产品，获取订单，扩大销售渠道。支持商品信息与订单信息自动同步与销售资金自动结算，保障供应商权益。',
        distribution:'分销平台可实现渠道销售企业可以一键上架商品,丰富商品结构扩大供应能力。支持订单信息自动同步，销售分佣自动结算，保障分销商权益。',
        payment:'依托中国工商银行与中国建设银行结算通道，为全行业提供支付接入服务，集成微信支付宝和银联支付等多种支付方式，方便企业快速开启线上经营收款，资金沉淀。',
        consult:'立即咨询',
        useFree: '免费试用'
    },
    payment:{
        aggregatePayTitle:'聚合支付',
        rateTitle:'阶梯费率',
        clearTitle:'智能清分',
        billTitle:'对账单',
        aggregatePay:'集成微信、支付宝、银行卡等多种支付方式',
        rate:'按照支付频率与金额，阶梯化费率，节省企业成本',
        clear:'智能结算，无需人员干预',
        bill:'提供对账单明细，交易流水明明白白'
    },
    existEvidence:{
        interfaceTitle:'标准接口',
        contractTitle:'智能合约',
        truthTitle:'溯源验真',
        customizedTitle:'定制服务',
        interface:'提供完善的标准接口，对接方便，快速部署',
        contract:'支持多种业务形态上链，场景丰富',
        truth:'提供溯源验真查询服务，为上链数据提供数字凭证',
        customized:'可针对企业个性化要求，快速响应部署',
    },
    market:{
        counterfeitTitle:'假冒伪劣产品泛滥',
        traditionOriginTitle :'传统溯源公信力不足',
        costTitle:'企业获客成本高',
        circulationTitle:'产品流通成本高',
        counterfeit:'三无产品假冒产品，发生问题难以回溯',
        traditionOrigin :'传统溯源采用中心化系统，数据容易被篡改',
        cost:'线下采购的客户无法与企业直接建立连接',
        circulation:'渠道串货，深受其扰',
        saleTitle:'码上营销',
        morePortTitle:'多端口扫码',
        chainDataTitle:'上链数据智能核验',
        tracebelTitle:'自定义溯源流程',
        sale:'消费者扫码后参与官方活动，跳转官方商城、关注公众号等，完成线下到线上闭环，引入流量',
        morePort:'支持微信支付宝双端口无感扫码',
        chainData:'为上链数据生成数字凭证，支持扫码核验',
        tracebel:'企业可根据生产过程自定义溯源环节或直接使用行业溯源模板',
    },
    onlineRetailers:{
        microMallTitle:'瑞麟微商城',
        distributorTitle:'瑞麟分销员',
        marketTitle:'瑞麟营销',
        saleTitle:'分销市场',
        microMall:'为企业快速搭建微信商城提供店铺装修模板，客户管理，订单管理，售后服务等模块，助力企业完成在线销售。',
        distributor:'可快速纳入分销员为商城进行商品分销，支持分销员审核，商品推广分润比例设置，业绩核算，佣金自动结算与自主提现等。',
        market:'为商城提供多种丰富的运营工具，包括但不限于优惠券、满减、拼团、裂变红包等，方便商家进行营销推广。',
        sale:'入驻瑞麟微商城的企业可以互相成为供货商与分销商，商城提供商品的上架与分销功能，支持订单同步，自动结算佣金与货款。',
    },
    user:{
        companyIntroductTitle:'深圳市瑞麟数字科技控股有限公司',
        companyIntroduct:'深圳市瑞麟数字科技控股有限公司是一家企业服务公司，是运用大数据云计算、物联网及人工智能技术，为企业提供专业的数字化解决方案的综合服务商。我们帮助每一家重视产品和服务，致力于提高影响力的企业完成品牌数字化升级，构建私域流量，拓展销售渠道，提高经营效率，全面助力企业成功。公司目前的产品和解决方案包括但不限于溯源营销服务平台、社交电商服务平台、供应链分销平台、物业电商平台等。',
    },
    distributionTitle:{
        goodesTitle:'产品丰富',
        selectGoodsTitle:'选货灵活',
        settlementTitle:'智能结算',
        goodes:'分销平台集成众多产业集群与行业，产品种类丰富，并提供检索服务，方便分销商选择和采购。',
        selectGoods:'可以与分销商商城系统进行API接口对接，传递信息与订单',
        settlement:'消费者确认收货，系统自动结算佣金，无需人员干预'
    },
    foot:{
        aboutUs:'关于我们',
        introduct:'企业介绍',
        build:'公司架构',
        culture:'企业文化',
        relevantPro:'相关产品',
        marketExist:'溯源营销  溯源存证',
        social:'云美装商户平台  供应链平台',
        retail:'分销平台  支付服务平台',
        contact:'联系我们',
        address:'深圳市福田区华富街道莲花一村社区',
        location:'彩田路7018号新浩壹都A4402B',
        net:'rlkg@ruilinsz.com',
        copyRight:'版权所有 ©深圳市瑞麟数字科技控股有限公司',
        ICPNum:' 粤ICP备2021043893号-1',
        recordNo:'',
        follow:'关注我们',
    },
    verify:{
        phoneNum: '请输入正确的手机号码',
        namePlease:'请输入姓名',
        phonePlease:'请输入手机号',
        max:'请勿输入数字和特殊符号、字数限制在20以内',
        success:'提交成功',
        submit:'提交',
        name:'称呼',
        phone:'手机号',
        titleZise:'填写信息',
        advantage:'优势',
        project:'方案架构',
        industry:'行业痛点',
        showPro:'产品展示',
        system:'溯源系统界面',
        h5:'溯源H5界面',
        tracebel:'溯源证书',
        scene:'应用场景',
        more:'更多方式敬请期待'
        
    }
}