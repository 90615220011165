import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const router = new VueRouter({
  mode:'history',
  routes: [
    {
      path: '/',
      component: () => import('@/pages/login.vue')
    }
  ],
})
router.beforeEach((to, from, next) => {
  if(to.path !== '/') {
    next('/')
  } else {
    next()
  }
})
export default router