import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import './theme/index.css'
import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'
import VueCookies from "vue-cookies"
import i18n from './i18n/index.js'
import http from './plugins/axios.js'


router.afterEach((to, from) => {
  window.scrollTo(0, 0)
})

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(VueCookies)
Vue.prototype.$http = http
new Vue({
  i18n,
  router,
  render: h => h(App)
}).$mount('#app')
