import axios from 'axios'
import { Message } from 'element-ui'

const baseURL = '/api'
axios.interceptors.request.use(
  config => {
    config.headers['Content-Type'] = 'application/json'
    return config
  },
  err => {
    Message.error(err)
    return Promise.reject(err)
  }
)

axios.interceptors.response.use(response => {
  if (response.status === 200) {
    const {
      data
    } = response
    if (data.code !== 200) {
      Message.error(data.message)
    }
    return response
  } else {
    Message.error(response.status)
  }
}, (error) => {
  Message.error(error.toString())
  return Promise.reject(error)
})
export default{
  post (url, data) {
    return axios({ method: 'post',
      url: `${baseURL}${url}`,
      data: data
    }) 
  },
  get (url, params) {
    return axios({ method: 'get',
      url: `${baseURL}${url}`,
      params: params
    })
  }
}
